import React, { useEffect, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Navbar from "../components/Navbar";
import { BsPenFill } from "react-icons/bs";
import { GrNotes } from "react-icons/gr";
import { GoQuote } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { Textarea } from "@chakra-ui/textarea";
import Placeholder from "@tiptap/extension-placeholder";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import axios from "axios";
import { getUserDetails } from "../actions/loadUser";

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={
          editor.isActive("heading", { level: 1 })
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : " px-4 py-2 border black rounded-full"
        }
      >
        h1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={
          editor.isActive("heading", { level: 2 })
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        h2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={
          editor.isActive("heading", { level: 3 })
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        h3
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={
          editor.isActive("paragraph")
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        paragraph
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={
          editor.isActive("bold")
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={
          editor.isActive("italic")
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        italic
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={
          editor.isActive("strike")
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        strike
      </button>
      {/* <button
        onClick={() => editor.chain().focus().toggleHighlight().run()}
        className={
          editor.isActive("highlight")
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        highlight
      </button> */}
      {/* <button
        onClick={() => editor.chain().focus().setTextAlign("left").run()}
        className={
          editor.isActive({ textAlign: "left" })
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        left
      </button> */}
      {/* <button
        onClick={() => editor.chain().focus().setTextAlign("center").run()}
        className={
          editor.isActive({ textAlign: "center" })
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        center
      </button> */}
      {/* <button
        onClick={() => editor.chain().focus().setTextAlign("right").run()}
        className={
          editor.isActive({ textAlign: "right" })
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        right
      </button> */}
      {/* <button
        onClick={() => editor.chain().focus().setTextAlign("justify").run()}
        className={
          editor.isActive({ textAlign: "justify" })
            ? "bg-black text-white px-4 py-2 border black rounded-full"
            : "px-4 py-2 border black rounded-full"
        }
      >
        justify
      </button> */}
    </>
  );
};

function Experiment() {
  const [text, changeText] = useState("");
  const [show, changeShow] = useState("standard");
  const toast = useToast();
  const token = useSelector((state) => state.user.token);
  const [waiting, changeWaiting] = useState(false);
  const [waitingPublish, setWaitingPublish] = useState(false);
  const [numWords, changeNumWords] = useState(0);
  /*
  standard - show all options
  notes - notes view
  settings - settings view 

  */
  const [parameters, changeParameters] = useState({
    temperature: 0.7,
    length: 100,
    topP: 0.7,
    fPenalty: 1,
    pPenalty: 1,
  });
  const { temperature, length, topP, fPenalty, pPenalty } = parameters;
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const [showParameters, changeShowParamters] = useState(false);
  const [notes, changeNotes] = useState("");
  const [loadedContent, setLoadedContent] = useState(false);
  //left control is the width of the editor
  const [leftControl, changeLeftControl] = useState(25);
  const [showNestedMenu, changeShowNestedMenu] = useState(false);
  const userEmail = useSelector((state) => state.user.email);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: "Start Writing..",
        emptyNodeClass:
          "first:before:h-0 first:before:text-gray-400 first:before:float-left first:before:content-[attr(data-placeholder)] first:before:pointer-events-none",
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "text-left font-description prose prose-sm sm:prose lg:prose-lg xl:prose-lg m-5 focus:outline-none",
      },
    },
  });

  useEffect(() => {
    if (editor) {
      // editor.chain().focus().toggleHeading({ level: 1 }).run();
      editor.chain().focus().setParagraph().run();

      //bind events
      editor.on("update", ({ editor }) => {
        const toStoreJSON = editor.getJSON();
        // console.log(editor.storage)
        const numWords = editor.getText().split(" ").length;

        changeNumWords(numWords);

        localStorage.setItem("content", JSON.stringify(toStoreJSON));
      });
      editor.on("selectionUpdate", ({ editor }) => {});

      if (!loadedContent && localStorage.getItem("content") !== null) {
        console.log("found");
        console.log(JSON.parse(localStorage.getItem("content")));
        editor.commands.setContent(JSON.parse(localStorage.getItem("content")));
        setLoadedContent(true);

        const numWords = editor.getText().split(" ").length;

        changeNumWords(numWords);

        if (localStorage.getItem("notes") !== null) {
          changeNotes(localStorage.getItem("notes"));
        }
      }
    }
  }, [editor]);

  // const writeMore = () => {
  //   console.log(editor.getHTML());
  // };

  const injectHTML = () => {
    editor.commands.insertContent("<h1>This is the last line</h1>");
  };

  const writeMore = async () => {
    changeShowNestedMenu(true);
    changeLeftControl(10);
    if (!loggedIn) {
      return toast({
        position: "top",
        title: "Login to continue !",
        description: "",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    // if (editor.getText().length > 2000) {
    //   return toast({
    //     position: "top",
    //     title: "Max word limit reached, use the text between ''' as prompt",
    //     description: "",
    //     status: "error",
    //     duration: 9000,
    //     isClosable: true,
    //   });
    // }

    //interpretor - ** check for ''' for prompt limiting**

    let value = editor.getText();
    let prompt = value;

    if (prompt.length > 1000) {
      prompt = prompt.substring(prompt.length - 1000, prompt.length);
    }

    if (value.includes("'''")) {
      let firstIndex = value.indexOf("'''");
      let secondIndex = value.indexOf("'''", firstIndex + 3);
      console.log(firstIndex, secondIndex);
      prompt = value.substring(firstIndex + 3, secondIndex);
    }

    if (value.includes("[")) {
      let firstIndex = value.indexOf("[");
      let secondIndex = value.indexOf("]");
      console.log(firstIndex, secondIndex);
      let substringToRemove = prompt.substring(firstIndex, secondIndex + 1);
      prompt = prompt.replace("[", "");
      prompt = prompt.replace("]", "");
      let currValue = value;
      currValue = currValue.replace(substringToRemove, "");
      //put it back in editor
      editor.commands.setContent(currValue);
    }
    console.log(prompt);
    prompt = prompt + " ";

    try {
      changeWaiting(true);
      if (token) {
        axios.defaults.headers.common["x-auth-token"] = token;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        prompt,
        temperature: temperature,
        max_tokens: length,
        top_p: topP,
        frequency_penalty: fPenalty,
        presence_penalty: pPenalty,
      };
      console.log(body);
      const res = await axios.post(
        "https://technologydao.herokuapp.com/api/v1/write/prompt",
        body,
        config
      );

      console.log(res.data);

      const tokens = res.data.aiPoints;

      console.log(tokens);

      // dispatch(updateTokens(tokens));
      await getUserDetails(token);

      let replaced = res.data.data.replaceAll("\n", "");
      replaced = "<p>" + replaced + "</p>";

      editor.commands.insertContent(replaced);
      changeWaiting(false);
    } catch (error) {
      changeWaiting(false);
      console.log(error);
      let msg = error.message;
      if (error.response && error.response.data) {
        msg = error.response.data.msg;
      }
      toast({
        position: "top",
        title: "Server Error",
        description: msg,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const publish = async () => {
    const content = editor.getHTML();
    if (numWords <= 1) {
      return toast({
        position: "top",
        title: "Content is empty",
        description: "",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    try {
      setWaitingPublish(true);
      if (token) {
        axios.defaults.headers.common["x-auth-token"] = token;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        title: content.substring(
          content.indexOf("<h1>") + 4,
          content.indexOf("</h1>")
        ),
        description: "",
        content:content.split("</h1>")[1],
        wordCount: numWords,
        authorEmail: userEmail,
        published: true,
        forkedFrom: null,
        markdown: true
      };

      const res = await axios.post(
        "https://technologydao.herokuapp.com/api/v1/articles/create",
        body,
        config
      );

      toast({
        position: "top",
        title: "Published Successfully",
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setWaitingPublish(false);

      //clear the contents of the editor after successful publish
    } catch (error) {
      setWaitingPublish(false);
      console.log(error);
      let msg = error.message;
      if (error.response && error.response.data) {
        msg = error.response.data.msg;
      }
      toast({
        position: "top",
        title: "Server Error",
        description: msg,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="relative h-screen">
      <Navbar />
      <section className="p-4 flex flex-row flex-end w-full py-32">
        <div
          style={{ left: leftControl + "%" }}
          className="w-[50%] absolute relative overflow-auto"
        >
          <div className="pt-24 fixed bg-white z-40 py-4 top-0 w-[50%]">
            <MenuBar editor={editor} />
          </div>
          <div className="mt-4 pt-2 z-0 w-[100%]">
            <EditorContent editor={editor} />
          </div>
        </div>
        {showNestedMenu && (
          <div className="border-l fixed z-0 h-screen w-64 top-0 right-32">
            <div className="mt-32">
              <div className="flex flex-row px-4 justify-between items-center">
                <h2 className="text-black font-bold">Settings</h2>
                <div
                  onClick={() => {
                    changeShowNestedMenu(false);
                    changeLeftControl(25);
                    changeShow("standard");
                  }}
                  className="cursor-pointer"
                >
                  <AiOutlineClose />
                </div>
              </div>

              <div className="flex flex-col">
                <div className="px-4 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="Higher the value, more is the creativity of the model's response"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Randomness</p>
                      <p className="text-base">{temperature.toFixed(2)}</p>
                    </div>
                  </Tooltip>

                  <Slider
                    colorScheme="green"
                    max={1}
                    step={0.01}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, temperature: val })
                    }
                    defaultValue={0.7}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>

                <div className="px-4 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="Maximum number of words to generate for the given prompt"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Number of words</p>
                      <p className="text-base">{length}</p>
                    </div>
                  </Tooltip>

                  <Slider
                    colorScheme="green"
                    max={768}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, length: val })
                    }
                    defaultValue={length}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>

                <div className="px-4 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="alternative to randomness, do not change both at once"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Diversity in Thought</p>
                      <p className="text-base">{topP.toFixed(2)}</p>
                    </div>
                  </Tooltip>

                  <Slider
                    colorScheme="green"
                    max={1}
                    step={0.01}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, topP: val })
                    }
                    defaultValue={0.7}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>

                <div className="px-4 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="Increase model's likelihood to talk about new topics"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Prevent Repetition Factor</p>
                      <p className="text-base">{fPenalty.toFixed(2)}</p>
                    </div>
                  </Tooltip>

                  <Slider
                    colorScheme="green"
                    max={2}
                    step={0.01}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, fPenalty: val })
                    }
                    defaultValue={1}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>

                <div className="px-4 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="Decrease model's likelihood to repeat the same line verbatim"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Presence Penalty</p>
                      <p className="text-base">{pPenalty.toFixed(2)}</p>
                    </div>
                  </Tooltip>
                  <Slider
                    colorScheme="green"
                    max={2}
                    step={0.01}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, pPenalty: val })
                    }
                    defaultValue={1}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="fixed text-sm z-0 h-screen right-0 top-0 border">
          {show && show === "standard" && (
            <>
              {!waiting ? (
                <div
                  onClick={writeMore}
                  className="mt-32 flex flex-col justify-center items-center px-4 cursor-pointer py-4"
                >
                  <BsPenFill />

                  <p className="py-2">AI Co-writer</p>
                </div>
              ) : (
                <div
                  onClick={writeMore}
                  className="mt-32 flex flex-col justify-center items-center px-4 cursor-pointer py-4"
                >
                  <BsPenFill />

                  <Spinner
                    fontSize="0.5rem"
                    marginRight="0.5rem"
                    marginTop="1rem"
                  />
                </div>
              )}

              <div
                onClick={() => {
                  changeLeftControl(10);
                  changeShowNestedMenu(false);
                  changeShow("notes");
                }}
                className="mt-4 flex flex-col justify-center items-center px-4 cursor-pointer py-4"
              >
                <GrNotes />
                <p className="py-2">Scratchpad</p>
              </div>
              <div className="mt-4 flex flex-col justify-center items-center px-4 cursor-pointer py-4">
                <GoQuote color="grey" />
                <p className="py-2 text-slate-400">Paraphrase</p>
              </div>
              {/* <div
                onClick={() => changeShow("settings")}
                className="mt-4 flex flex-col justify-center items-center px-4 cursor-pointer py-4"
              >
                <FiSettings />
                <p className="py-2">Settings</p>
              </div> */}
            </>
          )}
          {show && show === "notes" && (
            <div className="mt-24 z-10 bg-white w-96 transition-all duration-500 ease">
              <div className="flex flex-row px-2 justify-between items-center">
                <h2 className="text-black font-bold">Notes</h2>
                <div
                  onClick={() => {
                    changeLeftControl(25);
                    changeShow("standard");
                  }}
                  className="cursor-pointer"
                >
                  <AiOutlineClose />
                </div>
              </div>
              <Textarea
                value={notes}
                onChange={(e) => {
                  changeNotes(e.target.value);
                  localStorage.setItem("notes", e.target.value);
                }}
                placeholder="Write notes.."
                size="md"
                resize="none"
                minHeight="80vh"
              />
            </div>
          )}
          {show && show === "settings" && (
            <div className="mt-24">
              <div className="flex flex-row px-2 justify-between items-center">
                <h2 className="text-black font-bold">Settings</h2>
                <div
                  onClick={() => changeShow("standard")}
                  className="cursor-pointer"
                >
                  <AiOutlineClose />
                </div>
              </div>

              <div className="flex flex-col">
                <div className="px-2 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="Higher the value, more is the creativity of the model's response"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Randomness</p>
                      <p className="text-base">{temperature.toFixed(2)}</p>
                    </div>
                  </Tooltip>
                  <Slider
                    colorScheme="green"
                    max={1}
                    step={0.01}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, temperature: val })
                    }
                    defaultValue={0.7}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>

                <div className="px-2 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="Maximum number of words to generate for the given prompt"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Number of words</p>
                      <p className="text-base">{length}</p>
                    </div>
                  </Tooltip>
                  <Slider
                    colorScheme="green"
                    max={768}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, length: val })
                    }
                    defaultValue={length}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>

                <div className="px-2 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="alternative to randomness, do not change both at once"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Diversity in Thought</p>
                      <p className="text-base">{topP.toFixed(2)}</p>
                    </div>
                  </Tooltip>
                  <Slider
                    colorScheme="green"
                    max={1}
                    step={0.01}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, topP: val })
                    }
                    defaultValue={0.7}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>

                <div className="px-2 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="Increase model's likelihood to talk about new topics"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Prevent Repetition Factor</p>
                      <p className="text-base">{fPenalty.toFixed(2)}</p>
                    </div>
                  </Tooltip>
                  <Slider
                    colorScheme="green"
                    max={2}
                    step={0.01}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, fPenalty: val })
                    }
                    defaultValue={1}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>

                <div className="px-2 py-4 w-full">
                  <Tooltip
                    textColor="white"
                    label="Decrease model's likelihood to repeat the same line verbatim"
                  >
                    <div className="flex flex-row justify-between">
                      <p className="text-base">Presence Penalty</p>
                      <p className="text-base">{pPenalty.toFixed(2)}</p>
                    </div>
                  </Tooltip>
                  <Slider
                    colorScheme="green"
                    max={2}
                    step={0.01}
                    aria-label="slider-ex-6"
                    onChange={(val) =>
                      changeParameters({ ...parameters, pPenalty: val })
                    }
                    defaultValue={1}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {!waitingPublish ? (
        <div className="flex flex-row fixed items-center bottom-2 left-[2%] md:w-[10%] lg:w-[10%]">
          <button onClick={publish}
          className="cursor-pointer bg-[#00b985] rounded-full px-8 py-2 bg-emerald-500">
            Publish
          </button>
        </div>
      ) : (
        <div className="flex flex-row fixed items-center bottom-2 left-[2%] md:w-[10%] lg:w-[10%]">
          <Spinner></Spinner>
          <button 
          className="cursor-pointer bg-[#00b985] rounded-full px-8 py-2 bg-emerald-500">
            Publish
          </button>
        </div>
      )}
      {/* <div
        onClick={publish}
        className="cursor-pointer bg-[#00b985] fixed bottom-2 left-[2%] rounded-full md:w-[10%] lg:w-[10%] px-8 py-2 bg-emerald-500"
      >
        Publish
      </div> */}
      <div className="bg-[#00b985] rounded-full fixed bottom-2 left-[35%] flex flex-row px-4 py-2">
        <p className="text-base mr-4">Word Count : {numWords}</p>
        <p className="text-base">
          Publish to receive {numWords * 0.05 + 1} TDP tokens
        </p>
      </div>
    </div>
  );
}

export default Experiment;
